<template>
  <div class="container-fluid p-4">
    <router-link
      :to="{
        name: 'reports'
      }"
      v-slot="{ href, route, navigate }"
      custom
    >
      <a :href="href" @click="navigate" class="mr-2"
        ><i class="fa fa-chevron-left mr-1"></i>Back</a
      >
    </router-link>
    <div class="d-flex flex-column pt-4"></div>
    <div class="d-flex justify-content-between">
      <h1>Evacuation Report</h1>
    </div>
    <div class="d-flex flex-column pt-4">
      <div class="d-flex flex-column">
        <b-card-group>
          <b-card class="bg-surface-2">
            <b-card-text>
              <h6>
                <strong
                  >Total number of visitor(s) who is still in the building :
                  {{ getVisitorCounter }}</strong
                >
              </h6>
            </b-card-text>
          </b-card>
        </b-card-group>
      </div>

      <div class="d-flex justify-content-end pt-4 pb-2 b">
        <b-button
          :disabled="remainingVisitsList.length === 0"
          @click="downloadPDF()"
        >
          <i class="fas fa-file-pdf mr-1"></i>Export as PDF
        </b-button>
      </div>

      <div class="d-flex justify-content-between">
        <!-- Main table element -->
        <b-table
          class="tableBorder"
          stacked="md"
          striped
          show-empty
          small
          sticky-header
          :items.sync="remainingVisitsList"
          :fields="checkTableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="checkinListFilter"
          :filter-included-fields="checkinListFilterOn"
          :total-rows="totalRows"
          :per-page="perPage"
          :current-page="currentPage"
          @sort-changed="sortChanged"
        >
          <template #cell(index)="row">
            <div v-if="perPage != 'all'">
              {{ row.index + 1 + (currentPage - 1) * perPage }}
            </div>
            <div v-else>
              {{ row.index + 1 }}
            </div>
          </template>

          <template #cell(visitor_photo)="row">
            <div class="d-flex flex-column align-items-start">
              <div v-for="visitor in row.item.visitors" :key="visitor.id">
                <div v-if="visitor.face_image == null">
                  {{ "-" }}
                </div>
                <div v-else>
                  <b-img
                    thumbnail
                    fluid
                    style="max-width: 100px"
                    v-for="(visitor, index) in row.item.visitors"
                    :key="'expected_' + index + '_' + visitor.id"
                    :src="'data:image/jpeg;base64,' + visitor.face_image"
                  />
                </div>
              </div>
            </div>
          </template>

          <template #cell(visitors_name)="row">
            <div class="d-flex flex-column align-items-start">
              <h6
                v-for="(visitor, index) in row.item.visitors"
                :key="'expected_' + index + '_' + visitor.id"
                class="mr-1"
              >
                <p style="font-size: var(--font-size-normal)">
                  {{ visitor.visitors_name | humanizeText }}
                </p>
              </h6>
            </div>
          </template>

          <template #cell(visitors_contact_number)="row">
            <div class="d-flex flex-column align-items-start">
              <div
                v-for="(visitor, index) in row.item.visitors"
                :key="'expected_contact_' + index + '_' + visitor.id"
                class="mr-1"
              >
                {{ visitor.visitors_contact_number }}
              </div>
            </div>
          </template>

          <template #cell(check_in_time)="row">
            <div>
              {{
                row.item.additional_metadata.check_in_time | unixToTime12Hour
              }}
            </div>
          </template>

          <template #cell(end_time)="row">
            <div v-if="hasCheckOut(row.item)">
              {{
                row.item.additional_metadata.check_out_time | unixToTime12Hours
              }}
            </div>
            <div v-else>-</div>
          </template>

          <template #cell(hosts_name)="row">
            <div v-for="host in row.item.hosts" :key="'_' + host.id">
              <p style="font-size: var(--font-size-normal)">
                {{ host.hosts_name | humanizeText }}
              </p>
            </div>
          </template>

          <template #cell(hosts_contact_number)="row">
            <div v-for="host in row.item.hosts" :key="'_' + host.id">
              {{ host.hosts_contact_number }}
            </div>
          </template>
        </b-table>
      </div>
      <!--pagination-->
      <!-- <div class="d-flex justify-content-between w-100" v-if="!busy && !search">
        <div class="d-flex">
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            @change="handlePerPageChange"
          ></b-form-select>
          Per Page
        </div>

        <div class="d-flex">
          <b-pagination
            size="sm"
            v-if="perPage !== 'all'"
            class="d-flex"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  data: function () {
    return {
      pageTitle: "VMS Dashboard",
      isViewActive: false,
      hasPermission: false,
      sortBy: null,
      sortDesc: null,
      sortDirection: "desc",
      searchCheck: false,
      searchExpected: false,
      hosts: null,
      visitors: null,
      visitsList: [],
      remainingVisitsList: [],
      oldVisitList: [],
      checkTableFields: [
        {
          key: "index",
          label: " No.",
          thStyle: { width: "3%" }
        },
        {
          key: "visit_code",
          label: "Visitation Code",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "visitor_photo",
          label: "Visitor Photo",
          thStyle: { width: "auto" }
        },
        {
          key: "visitors_name",
          label: "Visitor",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "visitors_contact_number",
          label: "Visitor Contact No.",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "check_in_time",
          label: "Time In",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "hosts_name",
          label: "Host Name",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "hosts_contact_number",
          label: "Host Contact No.",
          sortable: true,
          thStyle: { width: "auto" }
        }
      ],
      busy: false,
      search: false,
      // pagination
      totalRows: 0,
      currentPage: 1,
      perPage: 0,
      pageOptions: [5, 10, 15, { value: "all", text: "All" }],
      expectedListFilter: null,
      expectedListFilterOn: ["visit_code"],
      checkinListFilter: null,
      checkinListFilterOn: []
    };
  },
  computed: {
    ...mapGetters({
      getCheck: "visit/getCheck",
      getExpected: "visit/getExpected",
      getIntervalId: "visit/getIntervalId",
      getAPIServerURL: "session/getAPIServerURL",
      getVisitorCounter: "visit/getVisitorCounter",
      getCheckInCounter: "visit/getCheckInCounter",
      getCheckOutCounter: "visit/getCheckOutCounter",
      getExpectedVisitorCounter: "visit/getExpectedVisitorCounter",
      getCheckedInButNotYetCheckout: "visit/getCheckedInButNotYetCheckout"
    })
  },
  watch: {
    $route: function () {
      if (this.$route.query.per_page === "all") {
        this.currentPage = 1;
        this.perPage = this.$route.query.per_page;
      } else {
        this.currentPage = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page
          ? parseInt(this.$route.query.per_page)
          : 5;
      }
      this.getData();
    },
    getCheckedInButNotYetCheckout: function (n, o) {
      this.remainingVisitsList = n;
      this.oldVisitList = n;
      this.totalRows = this.remainingVisitsList.length;
      this.perPage = this.remainingVisitsList.length;
    }
  },
  created() {
    if (this.$route.query.per_page === "all") {
      this.currentPage = 1;
      this.perPage = this.$route.query.per_page;
    } else {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 5;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.resetVisit();
      this.getVisitList();
    },
    getVisitList() {
      this.visitsList = [];

      let $this = this;
      let API_URL = this.getAPIServerURL + "/api/visitorservice/visit/all/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result != undefined) {
            outer_loop: for (let key in result) {
              result[key]["visits"] != undefined &&
                Array.isArray(result[key]["visits"]);
              {
                validUrl = key;
                break outer_loop;
              }
            }
          }
          if (
            result[validUrl] != undefined &&
            Array.isArray(result[validUrl]["visits"])
          ) {
            for (let key3 in result[validUrl]["visits"]) {
              let today_date = moment().format("YYYY-MM-DD");
              let visitData = result[validUrl]["visits"][key3];

              visitData["visitors"] = null;
              visitData["hosts"] = null;

              let isBoth = false;
              let isStartDate = false;
              let isEndDate = false;

              if (
                visitData["visit_start_date"] === today_date &&
                visitData["visit_end_date"] === today_date
              ) {
                isBoth = true;
              } else if (
                visitData["visit_start_date"] === today_date &&
                visitData["visit_end_date"] !== today_date
              ) {
                isStartDate = true;
              } else if (
                visitData["visit_start_date"] !== today_date &&
                visitData["visit_end_date"] === today_date
              ) {
                isEndDate = true;
              }
              if (isBoth || isStartDate || isEndDate) {
                isBoth = false;
                isStartDate = false;
                isEndDate = false;

                $this.visitsList.push(result[validUrl]["visits"][key3]);
                //console.log("this.visitsList", this.visitsList);

                this.fetchVisitors(visitData);
                this.fetchHosts(visitData);
              }
            }
          }
          if (result[validUrl]["page"] !== undefined) {
            this.currentPage = parseInt(result[validUrl]["page"]);
          }

          if (result[validUrl]["size"] !== undefined) {
            this.perPage = parseInt(result[validUrl]["size"]);
          }
        })
        .catch((err) => {
          console.log("visits err", err);
        });
    },
    fetchHosts(visitData) {
      let $this = this;
      let API_URL =
        this.getAPIServerURL +
        "/api/visitorservice/visit/" +
        visitData.id +
        "/hosts/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result !== undefined) {
            outer_loop: for (let key in result) {
              if (result[key] !== undefined && Array.isArray(result[key])) {
                validUrl = key;
                break outer_loop;
              }
            }
          }
          if (
            result[validUrl] != undefined &&
            Array.isArray(result[validUrl])
          ) {
            for (let key2 in this.visitsList) {
              if (visitData.id == this.visitsList[key2].id) {
                for (let objectKey in result[validUrl]) {
                  result[validUrl][objectKey]["hosts_name"] =
                    result[validUrl][objectKey]["name"]; //change key[name] to key[hosts_name]
                  result[validUrl][objectKey]["hosts_contact_number"] =
                    result[validUrl][objectKey]["contact_number"];
                  delete result[validUrl][objectKey]["name"];
                  delete result[validUrl][objectKey]["contact_number"];
                }

                this.hosts = result[validUrl];
                $this.visitsList[key2].hosts = this.hosts;
                //console.log("this.visitsList", this.visitsList);
              }
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    fetchVisitors(visitData) {
      let $this = this;
      let API_URL =
        this.getAPIServerURL +
        "/api/visitorservice/visit/" +
        visitData.id +
        "/visitors/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result !== undefined) {
            outer_loop: for (let key in result) {
              if (result[key] !== undefined && Array.isArray(result[key])) {
                validUrl = key;
                break outer_loop;
              }
            }
          }

          if (
            result[validUrl] != undefined &&
            Array.isArray(result[validUrl])
          ) {
            for (let key2 in this.visitsList) {
              if (visitData.id == this.visitsList[key2].id) {
                for (let objectKey in result[validUrl]) {
                  result[validUrl][objectKey]["visitors_name"] =
                    result[validUrl][objectKey]["name"]; //change key[name] to key[visitor_name]
                  result[validUrl][objectKey]["visitors_contact_number"] =
                    result[validUrl][objectKey]["contact_number"];
                  delete result[validUrl][objectKey]["name"];
                  delete result[validUrl][objectKey]["contact_number"];
                }

                this.visitors = result[validUrl];
                $this.visitsList[key2]["visitors"] = this.visitors;

                //console.log("this.visitsList", $this.visitsList[key2]["visitors"]);
                if (
                  Object.prototype.hasOwnProperty.call(
                    this.visitsList[key2]["additional_metadata"],
                    "check_in_time"
                  )
                ) {
                  this.$store.dispatch(
                    "visit/addCheckInList",
                    this.visitsList[key2]
                  );
                } else {
                  this.$store.dispatch(
                    "visit/addExpectedList",
                    this.visitsList[key2]
                  );
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log("errorr", err);
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    resetVisit() {
      this.$store.dispatch("visit/reset");
    },

    //handle page
    handlePageChange(page) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      if (pageSize === "all") {
        this.$router.push({
          ...this.$route,
          query: {
            per_page: pageSize
          }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            per_page: parseInt(pageSize)
          }
        });
      }
    },
    //sort change
    sortChanged(e) {
      console.log("inside", e);
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc;

      if (this.sortBy == "visit_code") {
        if (this.sortDesc) {
          this.remainingVisitsList.sort((x, y) =>
            y[this.sortBy].localeCompare(x[this.sortBy], undefined, {
              numeric: true
            })
          );
        } else {
          this.remainingVisitsList.sort((x, y) =>
            x[this.sortBy].localeCompare(y[this.sortBy], undefined, {
              numeric: true
            })
          );
        }
      }

      if (
        this.sortBy == "visitors_name" ||
        this.sortBy == "visitors_contact_number"
      ) {
        this.remainingVisitsList.sort((x, y) => {
          if (this.sortDesc) {
            if (x.visitors[0][this.sortBy] > y.visitors[0][this.sortBy]) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (x.visitors[0][this.sortBy] < y.visitors[0][this.sortBy]) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      }
      if (
        this.sortBy == "hosts_name" ||
        this.sortBy == "hosts_contact_number"
      ) {
        this.remainingVisitsList.sort((x, y) => {
          if (this.sortDesc) {
            if (x.hosts[0][this.sortBy] > y.hosts[0][this.sortBy]) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (x.hosts[0][this.sortBy] < y.hosts[0][this.sortBy]) {
              return 1;
            } else {
              return -1;
            }
          }
        });
      }

      if (this.sortBy == "check_in_time") {
        if (this.sortDesc) {
          this.remainingVisitsList.sort((x, y) =>
            x.additional_metadata[this.sortBy] >
            y.additional_metadata[this.sortBy]
              ? -1
              : 1
          );
        } else {
          this.remainingVisitsList.sort((x, y) =>
            y.additional_metadata[this.sortBy] >
            x.additional_metadata[this.sortBy]
              ? -1
              : 1
          );
        }
      }
    },

    hasCheckOut(visitData) {
      return Object.prototype.hasOwnProperty.call(
        visitData["additional_metadata"],
        "check_out_time"
      );
    },

    //download pdf
    async downloadPDF() {
      if (this.remainingVisitsList.length > 0) {
        await this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text:
            "We are generating PDF report with " +
            this.remainingVisitsList.length +
            " events",
          message_type: "info"
        });
      }

      let result = this.remainingVisitsList;
      let bodyData = [];

      let i = 0;
      for (let key in result) {
        let data2 = [];
        let visitor = result[key].visitors;
        let hosts = result[key].hosts;

        if (
          result[key]["additional_metadata"].check_in_time != undefined &&
          result[key]["additional_metadata"].check_out_time == undefined
        ) {
          i += 1;
          data2.push(i + "");
          data2.push(result[key].visit_code);
          for (let v in visitor) {
            if (visitor[v].face_image != null) {
              data2.push("");
            } else {
              data2.push("-");
            }
            data2.push(visitor[v].visitors_name);
            data2.push(visitor[v].visitors_contact_number);
          }
          data2.push(
            moment(
              result[key]["additional_metadata"].check_in_time * 1000
            ).format("HH:mm A")
          );
          for (let h in hosts) {
            data2.push(hosts[h].hosts_name);
            data2.push(hosts[h].hosts_contact_number);
          }

          bodyData.push(data2);
        }
      }
      let doc = new jsPDF();
      doc.autoTable({
        html: "#my-table"
      });
      doc.autoTable({
        head: [
          [
            "No",
            "Visitation Code",
            "Visitor Photo",
            "Visitor Name",
            "Visitor Contact No",
            "Time In",
            "Host Name",
            "Host Contact No"
          ]
        ],
        body: bodyData,
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 20 },
          2: { cellWidth: 30 },
          3: { cellWidth: 20 },
          4: { cellWidth: 30 },
          5: { cellWidth: 20 },
          6: { cellWidth: 20 },
          7: { cellWidth: 30 }
        },
        bodyStyles: { minCellHeight: 15 },
        didDrawCell: (data) => {
          if (data.column.index === 2 && data.row.section === "body") {
            let rowIndex = data.row.index;
            let row = bodyData[rowIndex];
            if (row !== undefined) {
              let visit_code = row[1];
              let photo = this.getPersonPhoto(visit_code);
              console.log("photo", photo);
              if (photo == null) {
                let dim = data.cell.height - data.cell.padding("vertical");
                doc.addImage("-", "JPEG", data.cell.x, data.cell.y, dim, dim);
              } else {
                let dim = data.cell.height - data.cell.padding("vertical");
                doc.addImage(photo, "JPEG", data.cell.x, data.cell.y, dim, dim);
              }
            }
          }
        }
      });
      doc.save("Evacuation Report.pdf");
      await this.$store.dispatch("session/addGlobalAlertMessage", {
        message_text:
          "PDF report with " +
          this.visitsList.length +
          " events has been downloaded successfully",
        message_type: "info"
      });
    },
    getPersonPhoto(visit_code) {
      let defaultImage = "-";
      try {
        let hasPersonPhoto = false;
        let personPhoto = "";

        for (let i = 0; i < this.visitsList.length; i++) {
          let visits = this.visitsList[i];
          for (let k in visits.visitors) {
            let image = visits.visitors[k];
            if (visits.visit_code === visit_code && image.face_image !== "") {
              hasPersonPhoto = true;
              personPhoto = image.face_image;
              break;
            }
          }
        }
        if (hasPersonPhoto) {
          return personPhoto;
        } else {
          return defaultImage;
        }
      } catch (e) {
        console.log("getPersonPhotoByCarPlateNo", e);
        return defaultImage;
      }
    }
  }
};
</script>

<style lang="scss">
.calendars {
  background: var(--colour-nav-bg);
}

.drp-buttons {
  background: var(--colour-nav-bg);
}

.calendar-table {
  color: var(--colour-overlay-gray-dark);
}
</style>
